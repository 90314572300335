import { useState, useEffect } from 'react'
import { Outlet, Link, useLocation } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

const tabItems = [
  {
    label: 'Add Topic',
    path: '/add-topic'
  },
  {
    label: 'Topics',
    path: '/topics'
  },
  {
    label: 'Account',
    path: '/account'
  },
  {
    label: 'Contact',
    path: '/contact'
  }
]

export default function BasicTabs () {
  const location = useLocation()
  const findIndex = tabItems.findIndex(item => item.path === location.pathname)
  const activeTab = findIndex !== -1 ? findIndex : false

  const [value, setValue] = useState(activeTab)

  useEffect(() => {
    setValue(activeTab)
  }, [location])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        centered
      >
        {tabItems.map((tabItem, index) => (
          <Tab
            key={index}
            label={tabItem.label}
            component={Link}
            to={tabItem.path}
            value={index}
            sx={{
              '&.Mui-selected': {
                color: 'primary.main'
              }
            }}
          />
        ))}
      </Tabs>
      <Outlet />
    </Box>
  )
}
