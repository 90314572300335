import { useState, useContext } from 'react'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { apiUrl } from './config'
import PropTypes from 'prop-types'
import { AppContext } from './App'

const WriteTopic = ({ setIsAuthenticated }) => {
  const { setMessage } = useContext(AppContext)
  const [formData, setFormData] = useState({
    topic: ''
  })

  const navigate = useNavigate()

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const token = localStorage.getItem('token')
    try {
      const response = await fetch(`${apiUrl}/create_topic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`

        },
        body: JSON.stringify({
          topic: formData.topic
        })
      })
      if (response.ok) {
        setMessage('Topic was created')
        navigate('/topics', { replace: true })
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token')
          setIsAuthenticated(false)
          setMessage('User Unauthorized')
          return
        }
        throw new Error(`HTTP Error ${response.status}`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const maxCharacters = 140
  const remainingCharacters = maxCharacters - formData.topic.length

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" align="center" sx={{ mb: 2 }}>
          Write a Topic
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Topic"
            name="topic"
            multiline
            rows={4}
            value={formData.topic}
            onChange={handleInputChange}
            inputProps={{ maxLength: maxCharacters }}
            helperText={`${remainingCharacters} characters remaining`}
          />
          <Button type="submit" variant="contained" sx={{ mt: 3 }}>
            Send
          </Button>
        </form>
      </Box>
    </Container>
  )
}

WriteTopic.propTypes = {
  setIsAuthenticated: PropTypes.func
}
export default WriteTopic
