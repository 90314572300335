import { useContext, useEffect, useState } from 'react'
import { FixedSizeList } from 'react-window'
import { List, ListItemButton, ListItemText, Box, Divider, Typography } from '@mui/material'
import AutoSizer from 'react-virtualized-auto-sizer'
import PropTypes from 'prop-types'
import { topPadding } from './styles'

import { Link } from 'react-router-dom'
import { apiUrl } from './config'
import { AppContext } from './App'

const Row = ({ index, style, data }) => (
  <>
    <Link to={`/topics/${data[index]._id}`} style={{ ...style, display: 'block' }}>
      <ListItemButton>
        <ListItemText primary={data[index].topic} />
      </ListItemButton>
      <Divider />
    </Link>
  </>
)

Row.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.array
}

const ReactWindowList = (props) => {
  const [items, setItems] = useState([])
  const { setIsAuthenticated } = props
  const { setLoading } = useContext(AppContext)

  useEffect(() => {
    const getData = async () => {
      try {
        const token = localStorage.getItem('token')
        const response = await fetch(`${apiUrl}/topics`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        setLoading(false)
        if (response.ok) {
          const data = await response.json()
          setItems(data?.topics)
        } else {
          if (response.status === 401) {
            localStorage.removeItem('token')
            setIsAuthenticated(false)
            return
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
    setLoading(true)
    getData()
  }, [])
  return (
    items?.length
      ? <Box sx={{ height: `calc(100vh - ${topPadding + 16}px )` }}>
      <List sx={{ height: '100%', overflow: 'auto' }}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemCount={items.length}
              itemSize={50}
            >
              {({ index, style }) => (
                <Row index={index} style={style} data={items} />
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </List>
    </Box>
      : <Box sx={{ padding: 1 }}>
        <Typography variant="body1">No items yet.</Typography>
      </Box>
  )
}

ReactWindowList.propTypes = {
  setIsAuthenticated: PropTypes.func
}
export default ReactWindowList
