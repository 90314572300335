import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import ListOfTopics from './List'
import AppBar from './AppBar'
import TabBar from './TabBar'
import WriteTopic from './WriteTopic'
import Login from './login'
import JoinNow from './join'
import { useEffect, useState, createContext } from 'react'
import PropTypes from 'prop-types'
import AppDescription from './LandingPage'
import Topic from './Topic'
import Account from './Account'
import AnalyticsMessage from './AnalyticsMessage'
import Contact from './Contact'
import VerifyAccount from './VerifyAccount'
import { Box, CircularProgress, Snackbar } from '@mui/material'
import { topPadding } from './styles'

export const AppContext = createContext(null)

function PrivateRoute ({ children, isAuthenticated }) {
  return isAuthenticated ? <>{children}</> : <Navigate to="/login" />
}

PrivateRoute.propTypes = {
  children: PropTypes.object,
  isAuthenticated: PropTypes.bool
}

function App () {
  const authentication = Boolean(localStorage.getItem('token')) || false
  const [isAuthenticated, setIsAuthenticated] = useState(authentication)
  const [hasAcceptedCookies, setCookie] = useState(localStorage.getItem('cookiesAccepted') === 'true')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true')
    setCookie(true)
  }

  const handleClose = () => {
    setMessage('')
  }

  return (
    <AppContext.Provider value={{
      setMessage,
      setLoading
    }}>
      <Router>
        <AppBar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
        <TabBar />
        <RoutesApp isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
        {!hasAcceptedCookies && (
          <AnalyticsMessage handleAcceptCookies={handleAcceptCookies} />
        )}
      </Router>
      {message && <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(message)}
        onClose={handleClose}
        message={message}
        key={'bottom' + 'center'}
      />}
      {loading && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `calc(100vh - ${topPadding}px )` }}>
        <CircularProgress />
      </Box>}
    </AppContext.Provider>
  )
}

const RoutesApp = ({ isAuthenticated, setIsAuthenticated }) => {
  const location = useLocation()
  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: document.title
    })
  }, [location])

  return (
    <Routes>
      <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
      <Route path="/join" element={<JoinNow />} />
      <Route path="/" element={<AppDescription />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/verify-account/:token" element={<VerifyAccount />} />

      <Route
        path="/add-topic"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <WriteTopic setIsAuthenticated={setIsAuthenticated} />
          </PrivateRoute>
        }
      />
      <Route
        path="/topics"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <ListOfTopics setIsAuthenticated={setIsAuthenticated} />
          </PrivateRoute>
        }
      />
      <Route
        path="/topics/:topicId"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Topic setIsAuthenticated={setIsAuthenticated} />
          </PrivateRoute>
        }
      />
      <Route
        path="/account"
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Account setIsAuthenticated={setIsAuthenticated} />
          </PrivateRoute>
        }
      />
    </Routes>
  )
}

RoutesApp.propTypes = {
  isAuthenticated: PropTypes.bool,
  setIsAuthenticated: PropTypes.func
}

export default App
