import { AppBar, Toolbar, Typography, Button } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { grey } from '@mui/material/colors'

const Header = (props) => {
  const { isAuthenticated, setIsAuthenticated } = props
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.removeItem('token')
    setIsAuthenticated(false)
    navigate('/login')
  }
  return (
    <AppBar position="sticky">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <NavLink to="/" style={{ color: 'white', textDecoration: 'none' }}>
            I Want To
          </NavLink>
        </Typography>
        {!isAuthenticated
          ? <Button color="inherit">
            <NavLink
              to="/login"
              style={{ color: 'white', textDecoration: 'none' }}
            >
              Login
            </NavLink>
          </Button>
          : <Button onClick={handleLogout} color="inherit">
            Logout
          </Button>
        }
        <Button disabled={isAuthenticated} color="inherit">
          <NavLink
            to="/join"
            style={{ color: isAuthenticated ? grey[500] : 'white', textDecoration: 'none' }}
          >
            Join Now
          </NavLink>
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default Header

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  setIsAuthenticated: PropTypes.func
}
