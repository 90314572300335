import { Typography, Box } from '@mui/material'

const Contact = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 2 }}>
      <Typography variant="h5" gutterBottom>Contact details</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Konstantinos Dalkafoukis
        <br />
        email: kdalkafoukis@gmail.com
      </Typography>
      <Typography variant="body2" align="center" sx={{ mt: 3 }}>
        This website was built by Konstantinos Dalkafoukis, a passionate software engineer.
      </Typography>
    </Box>
  )
}

export default Contact
