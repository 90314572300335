import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { apiUrl } from './config'

function AccountVerification () {
  const { token } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    async function verifyAccount () {
      try {
        const response = await fetch(`${apiUrl}/account-verification/${token}`)
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        setIsLoading(false)
      } catch (error) {
        setError(error)
        setIsLoading(false)
      }
    }
    verifyAccount()
  }, [])

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  return (
    <div>
      <p>Account verified. You can now go to login page</p>
      <Button variant="contained" color="primary" onClick={() => navigate('/login')}>
        Log in
      </Button>
      <p>.</p>
    </div>
  )
}

export default AccountVerification
